<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="MEMBER TIER"
        :filter="filter"
        placeholder="Search Tier name"
        @search="handleSearch"
        :hasDropdown="false"
        :hasFilter="false"
        routerPath="/membertier/detail/0"
      />
      <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
        <TableMemberListPanel
          :filter="filter"
          :pageOptions="pageOptions"
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :rows="rows"
          :showingTo="showingTo"
          @filterPage="filterPage"
          @deleteMemberTier="deleteMemberTier"
          @handleChangeTake="handleChangePerpage"
        />
      </div>
    </div>
    <ModalConfirm
      ref="confirmModal"
      :text="confirmMsg"
      @handler="deleteMember"
    />
    <ModalAlertError
      ref="alertModal"
      :showModal="showModal"
      :text="errorMsg"
      @hideModal="hideModal"
    />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import ModalConfirm from "@/components/modal/ModalConfirm";
import TableMemberListPanel from "@/components/memberTier/TableMemberListPanel";
import ModalAlertError from "@/components/coupon/modal/ModalAlertError";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  components: {
    OtherLoading,
    TableMemberListPanel,
    ModalConfirm,
    ModalAlertError,
    HeaderPanel,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        page: 1,
        take: 10,
        search: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "name",
          label: "Tier name",
        },
        {
          key: "upgrade_tier_name",
          label: "Upgrade Tier",
        },
        {
          key: "downgrade_tier_name",
          label: "Downgrade Tier",
        },
        {
          key: "baht_per_point",
          label: "Baht per point",
        },
        {
          key: "maintain_baht",
          label: "Mantain Baht",
        },
        {
          key: "membership_period",
          label: "Membership period",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      showingTo: 10,
      deleteId: 0,
      confirmMsg: "",
      showModal: false,
      errorMsg: "",
    };
  },
  async created() {
    await this.getMemberTierList();
  },
  methods: {
    async getMemberTierList() {
      this.isBusy = true;
      await this.$store.dispatch("getMemberTierList", this.filter);
      const data = this.$store.state.memberTier.memberTierList;
      if (data.result === 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.count;
        this.isLoading = false;
      }
      this.isBusy = false;
    },
    handleSearch() {
      this.getMemberTierList();
    },
    handleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.showingTo = value;
      this.getMemberTierList();
    },
    filterPage(value) {
      this.filter.page = value;
      this.getMemberTierList();
    },
    deleteMemberTier(id) {
      this.confirmMsg = "ต้องการลบ?";
      this.deleteId = id;
      this.$refs.confirmModal.show();
    },
    hideModal() {
      if (this.deleteId == 0) {
        window.location.href = "/membertier";
      }
    },
    async deleteMember(val) {
      if (val) {
        this.isLoading = true;
        await this.$store.dispatch("deleteMemberTier", this.deleteId);
        const data = this.$store.state.memberTier.deleteResp;
        if (data.result === 1) {
          window.location.href = `/membertier`;
        } else {
          this.isLoading = false;
          this.showModal = true;
          this.errorMsg = data.message;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
</style>
