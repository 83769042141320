<template>
  <div>
    <b-row class="mt-3">
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(maintain_baht)="data">
            <div>
              {{
                new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(data.item.maintain_baht)
              }}
            </div>
          </template>
          <template v-slot:cell(downgrade_tier_name)="data">
            {{
              data.item.downgrade_tier_name
                ? data.item.downgrade_tier_name
                : "-"
            }}
          </template>
          <template v-slot:cell(upgrade_tier_name)="data">
            {{
              data.item.upgrade_tier_name ? data.item.upgrade_tier_name : "-"
            }}
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <router-link :to="'/membertier/detail/' + data.item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
              <b-button
                variant="link"
                class="text-danger px-1 py-0"
                @click="deleteData(data.item.id)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="Delete"
                  class="main-color"
                />
              </b-button>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
      @handleChangeTake="$emit('handleChangeTake', $event)"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  methods: {
    pagination(page) {
      this.$emit("filterPage", page);
    },
    deleteData(id) {
      this.$emit("deleteMemberTier", id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: #f0b6c7;
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
